import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import EventForm from './event-form';
import moment, { duration } from 'moment';
import Link from 'next/link';
const extrapopins =Poppins({weight:"500", subsets:['latin']});
const quickSand =Quicksand({weight:"400", subsets:['latin']});
const popins =Poppins({weight:"400", subsets:['latin']});
export default function OurEvents() {
      //   Week END bATCH

      const eventDate = () => {
        const today = moment();
        const daysUntilSundayday = (7 - today.day() ) % 7; // Calculate days until next Sunday
        return today.add(daysUntilSundayday, 'days');
      };
     
      // Get the next Saturday's date and month
      const nextSunday = eventDate();
      const nextSundayDate = nextSunday.format('DD');
      const nextMonth = nextSunday.format('MMM');

  const event =[
    {
      img:"/course/mern-stack-developer.webp",
      heading:"Learn to Develop E-commerce",
      courseName:"MERN STACK",
      technologies:[
             "Mongodb",
             "ExpressJs",
             "ReactjS",
             "NodeJs"
      ],
      duration:"3",
      date:nextSundayDate,
      mnth:nextMonth,
      // date: nextSunday.getDate().toString(),
      // mnth: nextSunday.toLocaleString('default', { month: 'short' }),
      time:"09 Am",
      path:"/event/how-to-develop-ecommerce-website"
    }
    // ,
    // {
    //   img: "/course/web-design.webp",
    //   heading: "Mastering Web Design",
    //   courseName: "Full-Stack Development",
    //   technologies: [
    //     "HTML",
    //     "CSS",
    //     "JavaScript",
    //     "NodeJs"
    //   ],
    //   duration: "4",
    //   date: "15",
    //   mnth: "Sep",
    //   time: "11 Am",
    //   path:"/event/mern-stack-event"
    // },
    // {
    //   img: "/course/a10.webp",
    //   heading: "Data Science Bootcamp",
    //   courseName: "Data Science",
    //   technologies: [
    //     "Python",
    //     "Pandas",
    //     "Machine Learning",
    //     "Deep Learning"
    //   ],
    //   duration: "5",
    //   date: "20",
    //   mnth: "Oct",
    //   time: "02 Pm",
    //   path:"/event/mern-stack-event"
    // }
  ]

    const [showForm, setShowForm] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({heading:"",courseName:""});
    // const [eventData, setEventData] = useState([]);

    // const getNextSunday = (date)=>{
    //   const day = date.getDay()
    //   const nextSunday = new Date(date);
    //   nextSunday.setDate(date.getDay() +(7 - day) % 7);
    //   return nextSunday;
    // }
    // useEffect(() => {
    //   const today = new Date();
    //   const nextSunday = getNextSunday(today);
    //   const event =[
    //     {
    //       img:"/course/agile-service.webp",
    //       heading:"Learn TO Develop E-commerce",
    //       courseName:"MERN STACK",
    //       technologies:[
    //              "Mongodb",
    //              "ExpressJs",
    //              "ReactjS",
    //              "NodeJs"
    //       ],
    //       duration:"3",
    //       // date:"25",
    //       // mnth:"Aug",
    //       date: nextSunday.getDate().toString(),
    //       mnth: nextSunday.toLocaleString('default', { month: 'short' }),
    //       time:"09 Am",
    //       path:"/event/mern-stack-event"
    //     }
    //     // ,
    //     // {
    //     //   img: "/course/web-design.webp",
    //     //   heading: "Mastering Web Design",
    //     //   courseName: "Full-Stack Development",
    //     //   technologies: [
    //     //     "HTML",
    //     //     "CSS",
    //     //     "JavaScript",
    //     //     "NodeJs"
    //     //   ],
    //     //   duration: "4",
    //     //   date: "15",
    //     //   mnth: "Sep",
    //     //   time: "11 Am",
    //     //   path:"/event/mern-stack-event"
    //     // },
    //     // {
    //     //   img: "/course/a10.webp",
    //     //   heading: "Data Science Bootcamp",
    //     //   courseName: "Data Science",
    //     //   technologies: [
    //     //     "Python",
    //     //     "Pandas",
    //     //     "Machine Learning",
    //     //     "Deep Learning"
    //     //   ],
    //     //   duration: "5",
    //     //   date: "20",
    //     //   mnth: "Oct",
    //     //   time: "02 Pm",
    //     //   path:"/event/mern-stack-event"
    //     // }
    //   ]
    //   setEventData(event)
    // },[])

const formOpen = (heading,course)=>{
  setSelectedCourse({heading,course})
    // setShowForm(true)
}
  return (
    <>
    <section className='bg-clr '>
    <div className='container'>
        <h1 className={`fs-33 tac cw ${extrapopins.className}`} > Our Events</h1>
   <div className='df fac fjse mfdc fw'>
    {event.map((items,index)=>{
      return(
    <div className='card mt30' key={index}>
    <h2 className={`fs25 mfs20 fwb ylw-clr tac pdt10 mpdt30`}>{items.heading}</h2>
      <div className='df tfdc tfac  pdt20'>
      <div className='img smdn'>
        {/* <Image className='img-radius' src={"/course/agile-service.webp"} width={270} height={180} alt='Event' loading='lazy'/> */}
        <Image className='img-radius' src={items.img} width={270} height={180} alt='Event' loading='lazy'/>

      </div>
      <div className='img smdb tdn'>
        {/* <Image className='img-radius' src={"/course/agile-service.webp"} width={270} height={180} alt='Event' loading='lazy'/> */}
        <Image className='img-radius' src={items.img} width={170} height={100} alt='Event' loading='lazy'/>

      </div>
      <div className='df fdc '>
      <p className={`fs-19 ${extrapopins.className} mmt10`}>Using Technologies</p>
      <p className={`fs-16 mt10 ${extrapopins.className}`}>{items.courseName}</p>
      {items.technologies.map((techname,index)=>{
        return(
      <ul className='mt10'key={index} >
        
        <li className={`fs-16 ${popins.className}`}>{techname}</li>

      </ul>
      )
    })}
      </div>
      </div>
      <p className="line-code mt16 mmt11"></p>
      <div className='pd10'>
        <h5 className={`fs-19 tac ylw-clr ${extrapopins.className}`}>Join Our {items.duration} - Hour Webinar</h5>
        <div className='mdf mfac mpdr10  df fjsa mt10'>
        <div>
        <div className='mnth-box df fac fjc'>
          <h4 className={`fs-19  mfs-16 cw ${extrapopins.className}`}>{items.mnth}</h4>
        </div>
        <div className='date-box df fac fjc'>
          <h4 className={`fs-19 mfs-16 cw ${extrapopins.className}`}>{items.date}</h4>
        </div>
        </div>
        <div className='vertical-code mdn ml20'></div>
        <div className='ml20'>
        <div className='mnth-box df fac fjc'>
          <h4 className={`fs-19 mfs-16 cw ${extrapopins.className}`}>Time</h4>
        </div>
        <div className='date-box df fac fjc'>
          <h4 className={`fs-19 mfs-16 cw ${extrapopins.className}`}>{items.time}</h4>
        </div>
        </div>
        <div className='vertical-code mdn ml20'></div>
        <div className=''>
        <p className={`fs-19 mfs-14 ${extrapopins.className}`}>Book Your Slot</p>
       <Link href={`${items.path}`}> <button className={`btn cw mt10  ${popins.className}`}  
      heading={selectedCourse.heading}
      course={selectedCourse.course}>More Details</button> </Link>
        {/* onClick ={()=>formOpen(items.heading,items.courseName)} */}
        </div>
    </div>
      </div>
      
    </div>
    )})}
 
   </div>
</div>

    </section>
    {/* {showForm && <EventForm heading={selectedCourse.heading}
                  courseName={selectedCourse.courseName} 
                  onClose={() => setShowForm(false)} />} */}

    <style jsx>
{`

    .btn {
            
              height: 40px;
              border-radius: 10px;
              background-color: #ffa900;
              cursor: pointer;
              font-size: 16px;
              line-height: 24px;
              letter-spacing:0.5px;
              border:none;
              font-weight: 500;
            }
.card{
width: 500px;
height: 100%;
border-radius: 30px;
background-color: #FFFFFF;
// padding: 20px;
}
 .vertical-code{
        width:1px;
        height:80px;
        background: linear-gradient(147deg, #FFE53B 0%, #fd3838 74%);
        // margin-left:56px;
     }
.date-box{
    width: 80px;
    height:42px;
    border-radius:0px 0px 16px 16px;
    border-bottom: 1px solid #FFFFFF;
    background-color: #FF8100; 
    box-shadow: 0px 4px 10px 0px #00000040;

}
    .mnth-box{
        width: 80px;
        height:42px;
        border-radius:16px 16px 0px 0px;
        border-bottom: 1px solid #FFFFFF;
        background-color: #FFA100;
        box-shadow: 0px 4px 10px 0px #00000040;

    }

  .line-code {
    width: 95%;
    border: 1px solid #d3d3d3;
    background-color: #d3d3d3;
    margin: 10px;
  }
 li {
      list-style-type: none; /* Remove default list styling */
      position: relative; /* Positioning for the custom dot */
      margin-bottom: 8px; /* Spacing between list items */
    }

    li::before {
      content: '•'; /* Custom dot */
      position: absolute;
      left: -20px; /* Position the dot to the left of the text */
      color: #FFE53B; /* Customize the color */
      font-size: 28px; /* Customize the size */
      line-height: 1; /* Adjust line height */
      
    }
.bg-clr{
background-color: #FFE53B;
background-image: linear-gradient(147deg, #FFE53B 0%, #fd3838 74%);
padding: 40px;
height: 100%;
}
.img{
position: relative;
right: 10%;

}
.tdn{
display: none;
}
.smdb{
display: none;
}

@media only screen and (max-width: 475px){
.smdb{
display: block;
}
.smdn{
display: none !important;
}
}
@media only screen and (max-width: 630px){

.card{
width: 100%;
height: 100%;
border-radius: 30px;
background-color: #FFFFFF;
// padding: 20px;
}
.tfdc{
flex-direction: column;
align-items: center;
}
    .btn {
              width: 100px;
              height: 40px;
              padding: 4px 12px; /* Assuming this is shorthand for top, right, bottom, left */
              border-radius: 10px;
              background-color: #ffa900;
              cursor: pointer;
              font-size: 16px;
              line-height: 24px;
              letter-spacing:0.5px;
              border:none;
              font-weight: 500;
            }
.img{
position: relative;
right: 0%;

}
.date-box{
    width: 50px;
    height:42px;
    border-radius:0px 0px 16px 16px;
    border-bottom: 1px solid #FFFFFF;
    background-color: #FF8100; 
    box-shadow: 0px 4px 10px 0px #00000040;
    
    text-align: center;
}
    .mnth-box{
      
        width: 50px;
        height:42px;
        border-radius:16px 16px 0px 0px;
        border-bottom: 1px solid #FFFFFF;
        background-color: #FFA100;
        box-shadow: 0px 4px 10px 0px #00000040;

    }
}
`}

    </style>
    
    </>
  )
}
